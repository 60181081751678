import React, { Component } from "react";
import moment from 'moment';
import './cost-form.css';
import {TransactionForm} from "../transaction-form/transaction-form";
import {Icon, Tooltip, Whisper} from 'rsuite';
import {CustomInput} from "../../../ui";
import {CostService} from "../../../services";

export class CostForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            form: {
                date: {
                    value: moment().format('YYYY-MM-DD HH:mm:ss'),
                    valid: true
                },
                name: {
                    value: null,
                    valid: true
                },
                transactions: [],
                valid: true
            },
            opened_transaction: null,
            show_transaction_form: false,
            total_info_obj: null,
            total_info: [],
            form_inited: false,
        };
    }

    componentDidMount() {
        if(this.props.cost){
            this.setState(prevState => {
                prevState = this.state.form;
                prevState['id'] = this.props.cost.id || null;
                prevState['date'].value = this.props.cost.date || prevState['date'].value;
                prevState['name'].value = this.props.cost.name || prevState['name'].value;
                prevState['transactions'] = this.props.cost.transactions || prevState['transactions'];

                if(prevState['transactions'] && prevState['transactions'].length){
                    this.refreshTotalInfo();
                }

                return {form: prevState}
            }, () => {
                console.log('001: ', this.state.form);
                this.setState({form_inited: true});
            });
        } else {



            this.setState({form_inited: true});
        }
    }

    refreshTotalInfo(){
        if(this.props.accounts){
            let total_info_obj = {};
            for(let i=0; i<this.props.accounts.length;i++){
                total_info_obj[this.props.accounts[i].id] = {
                    id: this.props.accounts[i].id,
                    name: this.props.accounts[i].name,
                    summ: 0
                }
            }

            for(let i=0;i<this.state.form.transactions.length;i++){
                if(this.state.form.transactions[i].transaction_type === 'default'){
                    if(this.state.form.transactions[i].type === 'in'){
                        total_info_obj[this.state.form.transactions[i].account_id].summ += parseFloat(this.state.form.transactions[i].amount);
                    } else {
                        total_info_obj[this.state.form.transactions[i].account_id].summ -= parseFloat(this.state.form.transactions[i].amount);
                    }
                } else {
                    total_info_obj[this.state.form.transactions[i].account_from].summ -= parseFloat(this.state.form.transactions[i].amount_from);
                    total_info_obj[this.state.form.transactions[i].account_to].summ += parseFloat(this.state.form.transactions[i].amount_to);
                }
            }

            this.setState({total_info_obj}, () => {
                let total_info = [];

                for(let key in this.state.total_info_obj){
                    if(this.state.total_info_obj[key].summ !== 0){
                        total_info.push({
                            summ: this.state.total_info_obj[key].summ.toFixed(2),
                            name: this.state.total_info_obj[key].name
                        });
                    }
                }

                this.setState({total_info});
            })
        }
    }

    formChanged(field, event){
        this.setState(prevState => {
            prevState = this.state.form;
            prevState[field].value = event.value;
            return {form: prevState}
        });
    }

    addNewTransaction(){
        this.setState({show_transaction_form: true});
    }

    openTransaction(transaction){
        transaction.form_type = transaction.internal_transaction_id || transaction.account_from  || transaction.account_to ? 'internal' : 'default';
        this.setState({opened_transaction: transaction}, () => {
            this.setState({show_transaction_form: true});
        });
    }

    closeTransaction(){
        this.setState({show_transaction_form: false}, () => {
            this.setState({opened_transaction: null})
        });
    }

    transactionChanged(event){
        let changedTransaction = event;
        if(changedTransaction.internal_transaction_id || changedTransaction.form_type === 'internal'){
            changedTransaction.transaction_type = 'internal';

        } else {
            changedTransaction.transaction_type = 'default';
        }
        this.setState(prevState => {
            prevState = this.state.form;
            if(prevState['transactions'] && prevState['transactions'].length){
                let exist = false;
                for(let i=0;i<prevState['transactions'].length;i++){
                    if(prevState['transactions'][i].id === event.id){
                        prevState['transactions'][i] = changedTransaction;
                        exist = true;
                    }
                }
                if(!exist){
                    prevState['transactions'].push(changedTransaction);
                }
            } else {
                prevState['transactions'].push(changedTransaction);
            }

            return {form: prevState}
        }, () => {
            this.refreshTotalInfo();
            this.setState({show_transaction_form: false}, () => {

            });
        });
    }

    saveCost(){
        if(this.state.form.date.valid && this.state.form.name.valid){
            if(this.state.form.id){
                CostService.updateCost(this.state.form.id, {
                    date: this.state.form.date.value,
                    name: this.state.form.name.value
                })
                    .then(addData => {
                        this.state.form.id = addData.id;
                        this.props.change({
                            id: this.state.form.id,
                            date: this.state.form.date,
                            name: this.state.form.name,
                            transactions: this.state.form.transactions,
                        });
                    });
            } else {
                CostService.addCost({
                    date: this.state.form.date.value,
                    name: this.state.form.name.value
                })
                    .then(addData => {
                        this.setState(prevState => {
                            prevState = this.state.form;
                            prevState['id'] = addData.id;
                            return {form: prevState}
                        });
                    });
            }

        }
    }

    render() {
        return (
            this.state.form_inited ? <div className={'cost-popup'}>
                    {
                        !this.state.show_transaction_form ? <div className={'w-100'}>
                            <CustomInput placeholder={'Дата и время'} label={'Дата и время'} type={'datetime'} required={true} validatorType={'dateTime'} showError={this.state.show_errors} value={this.state.form.date.value} change={(event) => this.formChanged('date', event)}/>
                            <CustomInput placeholder={'Название'} label={'Название'} required={true} validatorType={'text'} showError={this.state.show_errors} value={this.state.form.name.value} change={(event) => this.formChanged('name', event)}/>
                            <div className={'row transactions-list'}>
                                <div className={'col-12'}>
                                    <div className={'row'}>
                                        <div className={'col-4'}>Тип операции</div>
                                        <div className={'col-4'}>Тип транзакции</div>
                                        <div className={'col-2'}>Сумма</div>
                                        <div className={'col-2'}></div>
                                    </div>
                                    {
                                        this.state.form.transactions.length ?
                                            <div className={'row'}>
                                                <div className={'col-12'}>
                                                    {
                                                        this.state.form.transactions.map((item, i) => {
                                                            return <div className={'row'} key={i}>
                                                                <div className={'col-4'}>
                                                                    {item.transaction_type === 'default' ? 'Обычная' : null}
                                                                    {item.transaction_type === 'internal' ? 'Внутренняя' : null}
                                                                </div>
                                                                <div className={'col-4'}>
                                                                    {item.type === 'in' ? 'Приход' : null}
                                                                    {item.type === 'out' ? 'Уход' : null}
                                                                    {item.type === 'exchange' ? 'Обмен' : null}
                                                                    {item.type === 'replenished' ? 'Пополнение' : null}
                                                                    {item.type === 'cashed' ? 'Обналичивание' : null}
                                                                </div>
                                                                <div className={'col-2'}>
                                                                    {/*{item.transaction_type}-{item.amount}-{item.amount_from}-{item.amount_to}=*/}
                                                                    {item.transaction_type === 'default' ? item.amount : null}
                                                                    {item.transaction_type === 'internal' ? item.amount_from : null}
                                                                </div>
                                                                <div className={'col-2 action-buttons'}>
                                                                    <Icon icon='edit2' onClick={(event) => this.openTransaction(item)}/>
                                                                    <Icon icon='trash-o' onClick={(event) => this.openTransaction(item)}/>
                                                                </div>
                                                            </div>
                                                        })
                                                    }
                                                </div>
                                            </div> : null
                                    }
                                    {
                                        this.state.form.id ? <div className={'row mb-3'}>
                                            <div className={'col-12 d-flex justify-content-end'}>
                                                <div className={'add-transaction'} onClick={(event) => this.addNewTransaction()}>+</div>
                                            </div>
                                        </div> : null
                                    }
                                </div>
                            </div>
                            {
                                this.state.total_info && this.state.total_info.length ? <div className={'row'}>
                                    <div className={'col-12'}>
                                        Итого:
                                    </div>
                                    <div className={'col-12 total-info'}>
                                        {
                                            this.state.total_info.map((titem, tindex) => {
                                                return (
                                                    <div className={titem.summ > 0 ? 'total-item green' : 'total-item red'} key={tindex}>
                                                        {titem.name}: {titem.summ}
                                                    </div>
                                                );
                                            })
                                        }
                                    </div>
                                </div> : null
                            }
                        </div> : null
                    }
                    {
                        !this.state.show_transaction_form ? <div className={'w-100 d-flex justify-content-end'}>
                            <button className={'btn btn-success'} onClick={() => this.saveCost()}>Сохранить покупку</button>
                        </div> : null
                    }
                    {
                        this.state.show_transaction_form ? <div className={'w-100'}>
                            <div className={'back-button mb-3'} onClick={(event) => this.closeTransaction()}>Вернутся к покупке</div>
                            <TransactionForm transaction={this.state.opened_transaction} cost={{name: this.state.form.name.value, date: this.state.form.date.value, id: this.state.form.id}} change={(event) => this.transactionChanged(event)}/>
                        </div> : null
                    }

            </div> : null
        );
    }
}