import API from "../utils/Api";
import {Config} from "../config";

export default {
    async autocomplete(search) {
        return new Promise(async(res, rej) => {
            try {
                const response = await API.post(Config.apiUrl + 'account/autocomplete', {search});
                if(response && response.data && response.data.status === 'OK'){
                    res(response.data.data);
                } else {
                    // Show Error
                    rej(null)
                }
            } catch (e) {
                // Show Error
                rej(null)
            }
        });
    },
    async getList() {
        return new Promise(async(res, rej) => {
            try {
                const response = await API.get(Config.apiUrl + 'account/list');
                if(response && response.data && response.data.status === 'OK'){
                    res(response.data.data);
                } else {
                    // Show Error
                    rej(null)
                }
            } catch (e) {
                // Show Error
                rej(null)
            }
        });
    },
    async create(data) {
        return new Promise(async(res, rej) => {
            try {
                const response = await API.post(Config.apiUrl + 'account', data);
                if(response && response.data && response.data.status === 'OK'){
                    res(response.data.data);
                } else {
                    // Show Error
                    rej(null)
                }
            } catch (e) {
                // Show Error
                rej(null)
            }
        });
    },
    async update(id, data) {
        return new Promise(async(res, rej) => {
            try {
                const response = await API.put(Config.apiUrl + 'account/' + id, data);
                if(response && response.data && response.data.status === 'OK'){
                    res(response.data.data);
                } else {
                    // Show Error
                    rej(null)
                }
            } catch (e) {
                // Show Error
                rej(null)
            }
        });
    },
    async delete(id) {
        return new Promise(async(res, rej) => {
            try {
                const response = await API.delete(Config.apiUrl + 'account/' + id);
                if(response && response.data && response.data.status === 'OK'){
                    res(response.data.data);
                } else {
                    // Show Error
                    rej(null)
                }
            } catch (e) {
                // Show Error
                rej(null)
            }
        });
    },
}