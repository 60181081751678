import React from "react";
import {Link} from "react-router-dom";
import './Info.css';

export function Info() {
    return (
        <div>
            <h1>Info Page</h1>
            <p>Info content...</p>
        </div>
    );
}