import React, { Component } from "react";
import './Authorized.css';
import {Sidebar} from "./components/Sidebar/Sidebar";
import {Header} from "./components/Header/Header";
import {Dashboard} from "./Dashboard/Dashboard";
import {Transactions} from "./Transactions/Transactions";
import {Accounts} from "./Accounts/Accounts";
import {IncomeCategories} from "./IncomeCategories/IncomeCategories";
import {ExpenseCategories} from "./ExpenseCategories/ExpenseCategories";
import {Currencies} from "./Currencies/Currencies";
import { Switch, Route } from 'react-router-dom';

export class Authorized extends Component {
    render() {
        return (
            <div className="authorized">
                <Header/>
                <div className="page-block">
                    <Sidebar/>
                    <div className="page-content">
                        <Switch>
                            <Route exact path='/' component={Dashboard}/>
                            <Route path='/transactions' component={Transactions}/>
                            <Route path='/accounts' component={Accounts}/>
                            <Route path='/income-categories' component={IncomeCategories}/>
                            <Route path='/expense-categories' component={ExpenseCategories}/>
                            <Route path='/currencies' component={Currencies}/>
                        </Switch>
                    </div>
                </div>
            </div>
        );
    }
}
