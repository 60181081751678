import React, { Component } from "react";
import moment from 'moment';
import './transaction-form.css';
import {CustomInput, CustomSelect} from "../../../ui";
import {TransactionService} from "../../../services";
import 'rsuite/dist/styles/rsuite-default.css';
import { Icon } from 'rsuite';

export class TransactionForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            form: {
                date: {
                    value: moment().format('YYYY-MM-DD HH:mm:ss'),
                    valid: true
                },
                form_type: {
                    value: 'default',
                    valid: true
                },
                default_data: {
                    account_id: {
                        value: null,
                        valid: false
                    },
                    amount: {
                        value: null,
                        valid: false
                    },
                    transaction_category: {
                        value: null,
                        valid: false
                    },
                    type: {
                        value: 'out',
                        valid: true
                    },
                    comment: {
                        value: null,
                        valid: false
                    }
                },
                internal_data: {
                    account_from: {
                        value: null,
                        valid: false
                    },
                    account_to: {
                        value: null,
                        valid: false
                    },
                    amount_from: {
                        value: null,
                        valid: false
                    },
                    amount_to: {
                        value: null,
                        valid: false
                    },
                    rate: {
                        value: 1,
                        valid: true
                    },
                    commission: {
                        value: 0,
                        valid: true
                    },
                    type: {
                        value: 'exchange',
                        valid: true
                    }
                },
            },
            form_save_error: null,
            form_inited: false,
            show_errors: false,
            type_inited: false
        };
    }

    componentDidMount() {
        console.log('0000001: ', this.props.transaction);

        if(this.props.transaction){
            this.setState(prevState => {
                prevState = this.state.form;

                prevState['date'].value = this.props.transaction.date || prevState['date'].value;
                prevState['form_type'].value = this.props.transaction.form_type || prevState['form_type'].value;
                if(this.props.transaction.form_type === 'default'){
                    prevState['default_data']['account_id'].value = this.props.transaction.account_id || prevState['default_data']['account_id'].value;
                    prevState['default_data']['amount'].value = this.props.transaction.amount || prevState['default_data']['amount'].value;
                    prevState['default_data']['transaction_category'].value = this.props.transaction.transaction_category || prevState['default_data']['transaction_category'].value;
                    prevState['default_data']['type'].value = this.props.transaction.type || prevState['default_data']['type'].value;
                    prevState['default_data']['comment'].value = this.props.transaction.comment || prevState['default_data']['comment'].value;
                }
                if(this.props.transaction.form_type === 'internal'){
                    if(this.props.transaction.internal_transaction){
                        prevState['internal_data']['account_from'].value = this.props.transaction.internal_transaction.account_from;
                        prevState['internal_data']['account_to'].value = this.props.transaction.internal_transaction.account_to;
                        prevState['internal_data']['amount_from'].value = this.props.transaction.internal_transaction.amount_from;
                        prevState['internal_data']['amount_to'].value = this.props.transaction.internal_transaction.amount_to;
                        prevState['internal_data']['rate'].value = this.props.transaction.internal_transaction.rate;
                        prevState['internal_data']['commission'].value = this.props.transaction.internal_transaction.commission || 0;
                        prevState['internal_data']['type'].value = this.props.transaction.internal_transaction.type;
                    } else {
                        prevState['internal_data']['account_from'].value = this.props.transaction.account_from || prevState['internal_data']['account_from'].value;
                        prevState['internal_data']['account_to'].value = this.props.transaction.account_to || prevState['internal_data']['account_to'].value;
                        prevState['internal_data']['amount_from'].value = this.props.transaction.amount_from || prevState['internal_data']['amount_from'].value;
                        prevState['internal_data']['amount_to'].value = this.props.transaction.amount_to || prevState['internal_data']['amount_to'].value;
                        prevState['internal_data']['rate'].value = this.props.transaction.rate || prevState['internal_data']['rate'].value;
                        prevState['internal_data']['commission'].value = this.props.transaction.commission || prevState['internal_data']['commission'].value;
                        prevState['internal_data']['type'].value = this.props.transaction.type || prevState['internal_data']['type'].value;
                    }
                }
                prevState['id'] = this.props.transaction.id || null;
                return {form: prevState}
            }, () => {
                this.setState({form_inited: true});
            });
        } else {
            if(this.props.cost){
                this.setState(prevState => {
                    prevState = this.state.form;
                    prevState['date'].value = this.props.cost.date;
                    return {form: prevState}
                }, () => {
                    this.setState({form_inited: true});
                });
            } else {
                this.setState({form_inited: true});
            }
        }
    }

    formChanged(field, event){
        if(field === 'form_type'){
            this.setState(prevState => {
                prevState = this.state.form;
                prevState['form_type'].value = event.value ? 'internal' : 'default';
                return {form: prevState}
            });
        } else {
            this.setState(prevState => {
                prevState = this.state.form;
                prevState[field] = event;
                return {form: prevState}
            });
        }
    }

    defaultFormChanged(field, event)
    {
        this.setState(prevState => {
            prevState = this.state.form;
            prevState.default_data[field] = event;

            if(field === 'type' && this.state.type_inited){
                prevState.default_data['transaction_category'] = {
                    value: null,
                    valid: false
                }
            }
            return {form: prevState}
        }, () => {
            this.setState({type_inited: true});
        });
    }

    internalFormChanged(field, event){
        this.setState(prevState => {
            prevState = this.state.form;
            prevState.internal_data[field] = event;
            return {form: prevState}
        });
    }

    saveTransaction(){
        this.setState({show_errors: true});
        let valid = this.state.form.date.valid && this.state.form.form_type.valid;
        if(this.state.form.form_type.value === 'default'){
            valid = (this.state.form.default_data.account_id.valid && this.state.form.default_data.amount.valid && this.state.form.default_data.comment.valid && this.state.form.default_data.type.valid);
        }
        if(this.state.form.form_type.value === 'internal'){
            valid = (this.state.form.internal_data.account_from.valid && this.state.form.internal_data.account_to.valid && this.state.form.internal_data.amount_from.valid && this.state.form.internal_data.amount_to.valid && this.state.form.internal_data.rate.valid && this.state.form.internal_data.commission.valid && this.state.form.internal_data.type.valid);
        }

        if(valid){
            let chData = {
                date: this.state.form.date.value,
                form_type: this.state.form.form_type.value
            };

            if(this.state.form.form_type.value === 'default'){
                chData['account_id'] = this.state.form.default_data.account_id.value;
                chData['amount'] = this.state.form.default_data.amount.value;
                chData['transaction_category'] = this.state.form.default_data.transaction_category.value;
                chData['comment'] = this.state.form.default_data.comment.value;
                chData['type'] = this.state.form.default_data.type.value;
            }

            if(this.state.form.form_type.value === 'internal'){
                chData['account_from'] = this.state.form.internal_data.account_from.value;
                chData['account_to'] = this.state.form.internal_data.account_to.value;
                chData['amount_from'] = this.state.form.internal_data.amount_from.value;
                chData['amount_to'] = this.state.form.internal_data.amount_to.value;
                chData['rate'] = this.state.form.internal_data.rate.value;
                chData['commission'] = this.state.form.internal_data.commission.value;
                chData['type'] = this.state.form.internal_data.type.value;
            }

            if(this.props.cost)
                chData['cost_id'] = this.props.cost.id;

            if(!this.state.form.id){
                TransactionService.addTransaction(chData)
                    .then((savedData) => {
                        chData['id'] = savedData.id;
                        this.props.change(chData);
                    })
                    .catch((savedError) =>{

                    });
            } else {
                TransactionService.editTransaction(this.state.form.id, chData)
                    .then((savedData) => {
                        chData['id'] = savedData.id;
                        this.props.change(chData);
                    })
                    .catch((savedError) =>{

                    });
            }
        }
    }

    render() {
        return (
            this.state.form_inited ? <div className={'transaction-popup'}>
                <CustomInput placeholder={'Дата и время'} label={'Дата и время'} type={'datetime'} required={true} validatorType={'dateTime'} showError={this.state.show_errors} value={this.state.form.date.value} change={(event) => this.formChanged('date', event)}/>
                <div className={'row'}>
                    <div className={'col-6'}>
                        Тип транзакции:
                    </div>
                    <div className={'col-6'}>
                        <CustomInput checked_label={'Внутренняя'} unchecked_label={'Обычная'} value={this.state.form.form_type.value === 'internal'} type={'toggle'} showError={this.state.show_errors} change={(event) => this.formChanged('form_type', event)}/>
                    </div>
                </div>
                <div className={'row'}>
                    {
                        this.state.form.form_type.value === 'default' ?
                            <div className={'col-12'}>
                                <div className={'input-row-group'}>
                                    <CustomSelect service={'account'} keyVal={'id'} showVal={'name'} placeholder={'Выберите счет'} label={'Счет'} required={true} validatorType={'number'} showError={this.state.show_errors} value={this.state.form.default_data.account_id.value} change={(event) => this.defaultFormChanged('account_id', event)} />
                                    <div className={'separator'}>
                                        <Icon icon='angle-right' size='2x'/>
                                    </div>
                                    <CustomInput placeholder={'Сумма'} label={'Сумма'} type={'number'} required={true} validatorType={'float'} showError={this.state.show_errors} value={this.state.form.default_data.amount.value} change={(event) => this.defaultFormChanged('amount', event)} />
                                </div>
                                <CustomSelect placeholder={'Тип'} label={'Тип'} required={true} validatorType={'text'} showError={this.state.show_errors} value={this.state.form.default_data.type.value} options={[{id: 'in', name: 'Приход'},{id: 'out', name: 'Уход'}]} change={(event) => this.defaultFormChanged('type', event)} />
                                <CustomSelect type={'tree'} service={this.state.form.default_data.type.value === 'in' ? 'income-categories' : 'expense-categories'} placeholder={'Выберите категорию'} label={'Категория'} required={true} validatorType={'number'} showError={this.state.show_errors} value={this.state.form.default_data.transaction_category.value} change={(event) => this.defaultFormChanged('transaction_category', event)} />
                                <CustomInput placeholder={'Комментарий'} label={'Комментарий'} type={'textarea'} validatorType={'text'} showError={this.state.show_errors} value={this.state.form.default_data.comment.value} change={(event) => this.defaultFormChanged('comment', event)}/>
                            </div> : null
                    }
                    {
                        this.state.form.form_type.value === 'internal' ?
                            <div className={'col-12'}>
                                <div className={'input-row-group'}>
                                    <CustomSelect service={'account'} keyVal={'id'} showVal={'name'} placeholder={'Выберите счет откуда'} label={'Счет откуда'} required={true} validatorType={'number'} showError={this.state.show_errors} value={this.state.form.internal_data.account_from.value} change={(event) => this.internalFormChanged('account_from', event)} />
                                    <div className={'separator'}>
                                        <Icon icon='angle-right' size='2x' />
                                    </div>
                                    <CustomInput placeholder={'Сумма'} label={'Сумма'} type={'number'} required={true} validatorType={'float'} showError={this.state.show_errors} value={this.state.form.internal_data.amount_from.value} change={(event) => this.internalFormChanged('amount_from', event)} />
                                </div>
                                <div className={'input-row-group'}>
                                    <CustomSelect service={'account'} keyVal={'id'} showVal={'name'} placeholder={'Выберите счет куда'} label={'Счет куда'} required={true} validatorType={'number'} showError={this.state.show_errors} value={this.state.form.internal_data.account_to.value} change={(event) => this.internalFormChanged('account_to', event)} />
                                    <div className={'separator'}>
                                        <Icon icon='angle-right' size='2x'/>
                                    </div>
                                    <CustomInput placeholder={'Сумма'} label={'Сумма'} type={'number'} required={true} validatorType={'float'} showError={this.state.show_errors} value={this.state.form.internal_data.amount_to.value} change={(event) => this.internalFormChanged('amount_to', event)} />
                                </div>
                                <CustomInput placeholder={'Курс'} label={'Курс'} type={'number'} required={true} validatorType={'float'} showError={this.state.show_errors} value={this.state.form.internal_data.rate.value} change={(event) => this.internalFormChanged('rate', event)} />
                                <CustomInput placeholder={'Комиссия'} label={'Комиссия'} type={'number'} validatorType={'float'} showError={this.state.show_errors} value={this.state.form.internal_data.commission.value} change={(event) => this.internalFormChanged('commission', event)} />
                                <CustomSelect placeholder={'Тип'} label={'Тип'} options={[{id: 'exchange', name: 'Обмен'},{id: 'replenished', name: 'Пополнение'},{id: 'cashed', name: 'Обналичивание'}]} required={true} validatorType={'text'} showError={this.state.show_errors} value={this.state.form.internal_data.type.value} change={(event) => this.internalFormChanged('type', event)} />
                            </div> : null
                    }
                </div>
                <div className={'row mt-3'}>
                    <div className={'col-12 d-flex justify-content-end'}>
                        {
                            this.state.form_save_error ? <span className={'form-error'}>{this.state.form_save_error}</span> : null
                        }
                        <button className={'btn btn-success'} onClick={() => this.saveTransaction()}>Сохранить</button>
                    </div>
                </div>

            </div> : null
        );
    }
}