import API from "../utils/Api";
import {Config} from "../config";

export default {
    async autocomplete(service, search, default_value) {
        return new Promise(async(res, rej) => {
            try {
                const response = await API.post(Config.apiUrl + service + '/autocomplete', {search, default_value});
                if(response && response.data && response.data.status === 'OK'){
                    res(response.data.data);
                } else {
                    // Show Error
                    rej(null)
                }
            } catch (e) {
                // Show Error
                rej(null)
            }
        });
    }
}