import React from "react";
import {withRouter} from "react-router-dom";
import {Auth} from "../../../guards";
import './Header.css';

export const Header = withRouter(({ history }) => {
    return (
        <div className="header">
            <div className="logo"></div>
            <div className="signout" onClick={() => {Auth.signout(() => history.push("/"));}}>Выход</div>
        </div>
    );
});