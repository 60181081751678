import React from "react";
import MetaTags from 'react-meta-tags';
import {Message, InputPicker, DateRangePicker, Icon, Tooltip, Whisper, Pagination, Modal, Button} from 'rsuite';
import './ExpenseCategories.css';
import {ExpenseCategoryService} from '../../services';
import {CustomInput, CustomSelect} from "../../ui";

export class ExpenseCategories extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            list: [],
            opened: null,
            toDelete: null,
            showPopup: false,
            showDeletePopup: false,
        };

        this.close = this.close.bind(this);
        this.open = this.open.bind(this);
        this.closeDelete = this.closeDelete.bind(this);
        this.openDelete = this.openDelete.bind(this);
    }

    close() {
        this.setState({ showPopup: false });
    }

    open() {
        this.setState({ showPopup: true });
    }

    closeDelete() {
        this.setState({ showDeletePopup: false });
    }

    openDelete(event = null) {
        this.setState({ showDeletePopup: true });
    }


    componentDidMount() {
        setTimeout(async () => {
            await this.refreshList();
        });
    }

    refreshList = async () => {
        return new Promise((res) => {
            ExpenseCategoryService.getList()
                .then(acList => {
                    this.setState({list: acList}, () => {
                        res(true);
                    });
                })
        });
    };

    openItem(item = null){
        if(!item)
            item = {
                name: {
                    value: null,
                    valid: false
                },
                amount: {
                    value: null,
                    valid: true
                },
                currency_id: {
                    value: null,
                    valid: false
                },
                active: {
                    value: 1,
                    valid: true
                }
            };
        else
            item = {
                id: item.id,
                name: {
                    value: item.name,
                    valid: true
                },
                amount: {
                    value: item.amount,
                    valid: true
                },
                currency_id: {
                    value: item.currency_id,
                    valid: true
                },
                active: {
                    value: item.active,
                    valid: true
                }
            };

        this.setState({opened: item}, () => {
            this.setState({modalTitle: item && item.id ? 'Редактирование категории "' + item.name.value + '"' : 'Новая категория'}, () => {
                this.open()
            });
        });
    }

    openDeleteItem(item){
        this.setState({toDelete: item}, () => {
            this.openDelete();
        })
    }

    saveItem(){
        if(!this.state.opened || !this.state.opened.name || !this.state.opened.name.valid || !this.state.opened.amount || !this.state.opened.amount.valid || !this.state.opened.currency_id || !this.state.opened.currency_id.valid || !this.state.opened.active || !this.state.opened.active.valid){
            this.setState({form_save_error: 'Заполните все поля'}, () => {
                setTimeout(() => {
                    this.setState({form_save_error: null});
                }, 3000);
            })
        } else {
            let saveData = {
                name: this.state.opened.name.value,
                amount: this.state.opened.amount.value,
                currency_id: this.state.opened.currency_id.value,
                active: this.state.opened.active.value
            };

            if(this.state.opened.id){
                ExpenseCategoryService.update(this.state.opened.id, saveData)
                    .then(async (createData) => {
                        await this.refreshList();
                        this.close();
                    })
                    .catch(crError => {
                        this.setState({form_save_error: crError}, () => {
                            setTimeout(() => {
                                this.setState({form_save_error: null});
                            }, 3000);
                        })
                    })
            } else {
                ExpenseCategoryService.create(saveData)
                    .then(async (createData) => {
                        await this.refreshList();
                        this.close();
                    })
                    .catch(crError => {
                        this.setState({form_save_error: crError}, () => {
                            setTimeout(() => {
                                this.setState({form_save_error: null});
                            }, 3000);
                        })
                    })
            }
        }
    }

    deleteConfirm(){
        ExpenseCategoryService.delete(this.state.toDelete.id)
            .then(async (dData) => {
                await this.refreshList();
                this.closeDelete();
            })
            .catch(dError => {

            })
    }

    formChanged(field, event){
        this.setState(prevState => {
            prevState = this.state.opened;
            prevState[field].value = event.value;
            prevState[field].valid = event.valid;
            return {form: prevState}
        }, () => {
            console.log('this.state: ', this.state.opened);
        });
    }

    render() {
        return (
            <div className={'w-100'}>
                <MetaTags>
                    <title>Категории затрат</title>
                    <meta id="meta-description" name="description" content="Категории затрат" />
                    <meta id="og-title" property="og:title" content="Категории затрат" />
                    <meta id="og-image" property="og:image" content="https://i.pinimg.com/originals/10/cf/25/10cf25ff3b572b8d2458785914b8ed39.jpg" />
                </MetaTags>

                <div className={'col-12'}>
                    <h1>Категории затрат</h1>
                    <table className="table table-striped">
                        <thead>
                        <tr>
                            <th scope="col">id</th>
                            <th scope="col">Название</th>
                            <th scope="col">Состояние</th>
                            <th scope="col"></th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            this.state.list.map((el, i) => {
                                return <tr className={'c-pointer'} key={i}>
                                    <td>{el.id}</td>
                                    <td>{el.name}</td>
                                    <td>{el.amount}</td>
                                    <td>
                                        <div className={'d-flex justify-content-end'}>
                                            <Whisper onClick={() => this.openItem(el)} placement="left" trigger="hover" speaker={<Tooltip>Детали</Tooltip>}>
                                                <Icon icon='edit2'/>
                                            </Whisper>
                                            <Whisper onClick={() => this.openDeleteItem(el)} placement="left" trigger="hover" speaker={<Tooltip>Удалить</Tooltip>}>
                                                <Icon icon='trash-o ml-2'/>
                                            </Whisper>
                                        </div>
                                    </td>
                                </tr>
                            })
                        }
                        </tbody>
                    </table>
                </div>

                <div className={'add-buttons'}>
                    <Whisper placement="top" trigger="hover" speaker={<Tooltip>Добавить аккаунт</Tooltip>}>
                        <div className={'add-item'} onClick={() => this.openItem()}>
                            <Icon icon='plus' size="lg"/>
                        </div>
                    </Whisper>
                </div>

                <Modal size={'lg'} overflow={false} show={this.state.showPopup} onHide={this.close}>
                    <Modal.Header>
                        <Modal.Title>
                            <div className={'w-100 d-flex justify-content-center'}>
                                {this.state.modalTitle}
                            </div>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className={'modal-form my-scrollbar pt-2 pb-2'}>
                            <div className={'row'}>
                                <div className={'col-12'}>
                                    {
                                        this.state.opened ? <CustomInput placeholder={'Название'} label={'Название'} required={true} validatorType={'text'} showError={this.state.opened.name.value} value={this.state.opened.name.value} change={(event) => this.formChanged('name', event)}/> : null
                                    }
                                    {
                                        this.state.opened ? <CustomSelect service={'currency'} keyVal={'id'} showVal={'name'} placeholder={'Выберите валюту'} label={'Валюта'} required={true} validatorType={'number'} showError={this.state.show_errors} value={this.state.opened.currency_id.value} change={(event) => this.formChanged('currency_id', event)} /> : null
                                    }
                                    {
                                        this.state.opened ? <CustomInput placeholder={'Сумма'} label={'Сумма'} required={true} validatorType={'float'} showError={this.state.opened.amount.value} value={this.state.opened.amount.value} change={(event) => this.formChanged('amount', event)}/> : null
                                    }
                                    {
                                        this.state.opened ? <CustomInput type={'toggle'} checked_label={'Включен'} unchecked_label={'Выключен'} showError={this.state.opened.active.value} value={this.state.opened.active.value} change={(event) => this.formChanged('active', event)}/> : null
                                    }
                                </div>
                            </div>
                            <div className={'row mt-3'}>
                                <div className={'col-12 d-flex justify-content-end'}>
                                    {
                                        this.state.form_save_error ? <span className={'form-error'}>{this.state.form_save_error}</span> : null
                                    }
                                    <button className={'btn btn-success'} onClick={() => this.saveItem()}>Сохранить</button>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>

                <Modal backdrop="static" show={this.state.showDeletePopup} onHide={this.closeDelete} size="xs">
                    <Modal.Body>
                        <div className={'w-100 d-flex flex-column align-items-center'}>
                            <Icon icon="remind" style={{color: '#ffb300', fontSize: 24}} />
                            <span>Вы уверены что хотите удалить аккаунт</span>
                            {
                                this.state.toDelete ? <span>{this.state.toDelete.name ? '"' + this.state.toDelete.name + '"' : '№' + this.state.toDelete.id}</span> : null
                            }
                            <span className={'mb-4'}>?</span>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={this.closeDelete} appearance="subtle">
                            Отмена
                        </Button>
                        <Button onClick={(event) => this.deleteConfirm()} appearance="primary">
                            Удалить
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}
