import React from "react";
import './Login.css';
import {Auth} from "../../guards";
import {Redirect,} from "react-router-dom";
import { Form, Button } from 'react-bootstrap';

export class Login extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            email: null,
            password: null,
            redirectToReferrer: false
        };

        this.handleInputChange = this.handleInputChange.bind(this);
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    login = (event) => {
        event.preventDefault();
        Auth.authenticate({
            email: this.state.email,
            password: this.state.password
        }, () => {
            this.setState(() => ({
                redirectToReferrer: true
            }));
        });
    };

    render() {
        const { from } = this.props.location.state || { from: { pathname: "/" } };
        const { redirectToReferrer } = this.state;

        if (redirectToReferrer === true) {
            return <Redirect to={from} />;
        }

        return (
            <div className="login-page">
                <Form className="login-form" onSubmit={this.login}>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label>Email</Form.Label>
                        <Form.Control
                            type="email"
                            placeholder="Email"
                            value={this.state.email || ''}
                            onChange={e => this.setState({ email: e.target.value })}
                        />
                    </Form.Group>

                    <Form.Group controlId="formBasicPassword">
                        <Form.Label>Пароль</Form.Label>
                        <Form.Control
                            type="password"
                            placeholder="Пароль"
                            value={this.state.password || ''}
                            onChange={e => this.setState({ password: e.target.value })}
                        />
                    </Form.Group>
                    <Button variant="primary" type="submit">
                        Войти
                    </Button>
                </Form>
            </div>
        );
    }
}