import API from "../utils/Api";
import {Config} from "../config";

export default {
    async addTransaction(data) {
        return new Promise(async(res, rej) => {
            try {
                const response = await API.post(Config.apiUrl + 'transaction', data);
                if(response && response.data && response.data.status === 'OK'){
                    res(response.data.data);
                } else {
                    // Show Error
                    rej(null)
                }
            } catch (e) {
                // Show Error
                rej(null)
            }
        });
    },
    async editTransaction(id, data) {
        return new Promise(async(res, rej) => {
            try {
                const response = await API.put(Config.apiUrl + 'transaction/' + id, data);
                if(response && response.data && response.data.status === 'OK'){
                    res(response.data.data);
                } else {
                    // Show Error
                    rej(null)
                }
            } catch (e) {
                // Show Error
                rej(null)
            }
        });
    },
    async getListDefault(params) {
        return new Promise(async(res, rej) => {
            try {
                const response = await API.get(Config.apiUrl + 'transaction/default', {params});
                if(response && response.data && response.data.status === 'OK'){
                    res(response.data.data);
                } else {
                    // Show Error
                    rej(null)
                }
            } catch (e) {
                // Show Error
                rej(null)
            }
        });
    },
    async getListForAccount(params) {
        return new Promise(async(res, rej) => {
            try {
                const response = await API.get(Config.apiUrl + 'transaction/account', {params});
                if(response && response.data && response.data.status === 'OK'){
                    res(response.data.data);
                } else {
                    // Show Error
                    rej(null)
                }
            } catch (e) {
                // Show Error
                rej(null)
            }
        });
    },
    async getListInternal(params) {
        return new Promise(async(res, rej) => {
            try {
                const response = await API.get(Config.apiUrl + 'transaction/internal', {params});
                if(response && response.data && response.data.status === 'OK'){
                    res(response.data.data);
                } else {
                    // Show Error
                    rej(null)
                }
            } catch (e) {
                // Show Error
                rej(null)
            }
        });
    },
    async deleteDefaultTransaction(id) {
        return new Promise(async(res, rej) => {
            try {
                const response = await API.delete(Config.apiUrl + 'transaction/default/' + id);
                if(response && response.data && response.data.status === 'OK'){
                    res(response.data.data);
                } else {
                    // Show Error
                    rej(null)
                }
            } catch (e) {
                // Show Error
                rej(null)
            }
        });
    },
    async deleteInternalTransaction(id) {
        return new Promise(async(res, rej) => {
            try {
                const response = await API.delete(Config.apiUrl + 'transaction/internal/' + id);
                if(response && response.data && response.data.status === 'OK'){
                    res(response.data.data);
                } else {
                    // Show Error
                    rej(null)
                }
            } catch (e) {
                // Show Error
                rej(null)
            }
        });
    }

}