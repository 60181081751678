import React, { Component } from "react";
import { DatePicker, Toggle, Input, InputGroup } from 'rsuite';
import moment from 'moment';
import './custom-input.css';
import {Validate} from "../../libs";

export class CustomInput extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value: '',
            valid: {
                status: true,
                message: ''
            },
            inited: false
        };

        this.type = this.props.type || 'text';
        this.validatorType = this.props.validatorType || 'filled';
        this.required = this.props.required || false;
    }

    componentDidMount() {
        if(this.props.value){
            this.setStateValue((this.type === 'datetime' || this.type === 'date') ? new Date(this.props.value): this.props.value);
        } else {
            if(this.type === 'datetime' || this.type === 'date'){
                this.setStateValue(new Date());
            } else if(this.type === 'number' && this.props.value === 0){
                this.setStateValue(0);
            } else {
                this.setStateValue('');
            }
        }
    }

    setStateValue(value){
        this.setState({value: value}, () => {

            console.log('!!!!!!!!: ', value);

            this.setState({valid: Validate[this.validatorType](this.state.value, this.required)}, () => {
                this.props.change({
                    valid: this.state.valid && this.state.valid.status,
                    value: this.type === 'datetime' || this.type === 'date' ? moment(this.state.value).format('YYYY-MM-DD HH:mm:ss') : this.state.value
                });
                this.setState({inited: true})
            });
        });
    }

    setValue(event){
        this.setStateValue(event);
    }

    render() {
        return (
            this.state.inited ? <div className={(!this.state.valid.status && this.props.showError) ? 'custom-input-block error' : 'custom-input-block'}>
                <div className={'custom-label'}>{this.props.label}</div>
                {
                    this.type === 'text' || this.type === 'number' || this.type === 'password' ? <Input className={'form-control'} placeholder={this.props.placeholder} value={ this.state.value } type={this.type} onChange={ (event)=> this.setValue(event) } /> : null
                }
                {
                    this.type === 'textarea' ? <Input className={'form-control'} componentClass="textarea" rows={3} value={ this.state.value } placeholder={this.props.placeholder} onChange={ (event)=> this.setValue(event) }/> : null
                }
                {
                    this.type === 'datetime' ? <DatePicker isoWeek style={{width: '100%'}} format="YYYY-MM-DD HH:mm:ss" defaultValue={this.state.value ? new Date(moment(this.state.value).format('YYYY-MM-DD HH:mm:ss')) : new Date(moment().format('YYYY-MM-DD HH:mm:ss'))} ranges={[{label: 'Now', value: this.state.value}]} oneTap={true} placement="auto" onChange={(event)=> this.setValue(event)} /> : null
                }
                {
                    this.type === 'date' ? <DatePicker isoWeek style={{width: '100%'}} defaultValue={this.state.value ? new Date(moment(this.state.value).format('YYYY-MM-DD HH:mm:ss')) : new Date(moment().format('YYYY-MM-DD HH:mm:ss'))} ranges={[{label: 'Now', value: this.state.value}]} oneTap={true} placement="auto" onChange={(event)=> this.setValue(event)} /> : null
                }
                {
                    this.type === 'toggle' ? <Toggle size="lg" checkedChildren={this.props.checked_label} unCheckedChildren={this.props.unchecked_label} checked={!!this.state.value} onChange={(event)=> this.setValue(event)} /> : null
                }
                {
                    !this.state.valid.status && this.props.showError ? <span className={'custom-error'}>{this.state.valid.message}</span> : null
                }
            </div> : <div className={(!this.state.valid.status && this.props.showError) ? 'custom-input-block error' : 'custom-input-block'}></div>
        );
    }
}