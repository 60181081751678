import React, { Component } from "react";
import {
    BrowserRouter as Router,
    Route,
    Redirect,
    Switch
} from "react-router-dom";
import {Auth} from "./guards";
import {Authorized} from "./authorized/Authorized";
import {Login} from "./unauthorized/Login/Login";
import {NotFound} from "./404/NotFound";
import {Info} from "./Info/Info";

const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route
        {...rest}
        render={(props) =>
            Auth.user ? (
                <Component {...props} />
            ) : (
                <Redirect
                    to={{
                        pathname: "/login",
                        state: { from: props.location }
                    }}
                />
            )
        }
    />
);



export class App extends Component {
    render() {
        return (
            <Router>
                <div>
                    <Switch>
                        <Route exact path="/login" component={Login} />
                        <Route exact path="/info" component={Info} />
                        <PrivateRoute path="/" component={Authorized} />
                        <Route component={NotFound} />
                    </Switch>
                </div>
            </Router>
        );
    }
}
