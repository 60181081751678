import React from "react";
import MetaTags from 'react-meta-tags';
import '../../../node_modules/react-vis/dist/style.css';
import {XYPlot, LineSeries, Crosshair, DiscreteColorLegend, LineMarkSeries, XAxis, YAxis, HorizontalGridLines, VerticalGridLines} from 'react-vis';
import {Message, InputPicker, DateRangePicker, Icon, Tooltip, Whisper, Pagination, Modal, Button} from 'rsuite';
import moment from 'moment';
import './Dashboard.css';
import {StatisticsService, AccountService, CurrencyService} from '../../services';
const {afterToday} = DateRangePicker;

export class Dashboard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            crosshairValues: [],
            crosshairTitle: [],
            crosshairAllValues: [],
            crosshairAllTitle: [],
            chartData: [],
            chartAllData: [],
            accounts: [],
            filters: {
                transaction_type: null, // null | in | out
                categories_id: null,
                account: null,
                date: {
                    from: new Date(moment().add(-3, 'month').format('YYYY-MM-DD HH:mm:ss')),
                    to: new Date(moment().format('YYYY-MM-DD HH:mm:ss'))
                }
            },
            legend: [],
            legendAll: [],
            currentAllAccount: 2,
        };

        this.colors = [
            '#d62d20',
            '#008744',
            '#0057e7',
            '#ffe600',
            '#000000'
        ];
        this.currencies = [];
    }

    componentDidMount() {
        setTimeout(async () => {
            await this.refreshCurrencies();
            await this.refreshAccounts();
            await this.refreshList();
        });
    }

    refreshAccounts = async () => {
        return new Promise((res) => {
            AccountService.getList()
                .then(acList => {
                    this.setState({accounts: acList}, () => {
                        res(true);
                    });
                })
        });
    };

    refreshCurrencies = async () => {
        return new Promise((res) => {
            CurrencyService.getList()
                .then(curData => {
                    this.currencies = curData;
                    res(true);
                })
        });
    };

    refreshList = async () => {
        return new Promise((res) => {
            let filter;

            filter = {
                transaction_type: this.state.filters.transaction_type,
                categories_id: this.state.filters.categories_id,
                account: !this.state.allForAccount ? this.state.filters.account : this.state.allForAccount
            };
            if(this.state.filters.date && this.state.filters.date.from && this.state.filters.date.to){
                filter.startDate = this.state.filters.date.from;
                filter.endDate = this.state.filters.date.to;
            }

            this.setState({refresh: true}, () => {
                StatisticsService.getAll(filter)
                    .then(data => {
                        this.refreshChartData(data);
                        this.refreshChartAllData(data);
                    })
            })
        });
    };

    refreshChartData(list){
        let segmentSize = Math.floor(moment(this.state.filters.date.to).diff(moment(this.state.filters.date.from), 'seconds') / 30);
        let segments = [];

        let start = moment(this.state.filters.date.from).unix();
        for(let i=0;i<30;i++){
            let current = start + i*segmentSize;
            segments.push(current);
        }

        if(segments[29] < moment(this.state.filters.date.to).unix())
            segments[29] = moment(this.state.filters.date.to).unix();

        let chartDataObject = {};
        let legend = [];

        for(let i=0;i<this.state.accounts.length;i++){
            if(!this.state.filters.account || this.state.filters.account === this.state.accounts[i].id){
                chartDataObject[this.state.accounts[i].id] = {
                    name: this.state.accounts[i].name,
                    account_id: this.state.accounts[i].id,
                    data: []
                };

                legend.push({
                    title: this.state.accounts[i].name,
                    color: this.colors[i]
                });

                for(let j=0;j<segments.length;j++){
                    chartDataObject[this.state.accounts[i].id].data.push({
                        date: moment(segments[j] * 1000).format('YYYY-MM-DD'),
                        x: j,
                        yd: 0,
                    })
                }
            }
        }

        for(let i=0;i<list.length;i++){
            let data_index = this.getSegmentNumber(segments, list[i].date);

            if(list[i].type === 'in')
                chartDataObject[list[i].account_id].data[data_index].yd += parseFloat(list[i].amount);
            else
                chartDataObject[list[i].account_id].data[data_index].yd -= parseFloat(list[i].amount);
        }

        for(let cdkey in chartDataObject){
            for(let j=chartDataObject[cdkey].data.length-1;j>=0;j--){
                if(j === chartDataObject[cdkey].data.length-1){
                    chartDataObject[cdkey].data[j].y = this.getAccountAmount(chartDataObject[cdkey].account_id);
                } else {
                    chartDataObject[cdkey].data[j].y = chartDataObject[cdkey].data[j+1].y - chartDataObject[cdkey].data[j+1].yd
                    delete chartDataObject[cdkey].data[j+1].yd;
                }
            }
        }

        let chartData = [];
        for(let key in chartDataObject){
            chartData.push(chartDataObject[key]);
        }
        
        console.log('!!!!!!!: ', chartData);


        this.setState({chartData}, () => {
            this.setState({legend});
        })
    }

    refreshChartAllData(list){
        let segmentSize = Math.floor(moment(this.state.filters.date.to).diff(moment(this.state.filters.date.from), 'seconds') / 30);
        let segments = [];

        let start = moment(this.state.filters.date.from).unix();
        for(let i=0;i<30;i++){
            let current = start + i*segmentSize;
            segments.push(current);
        }

        if(segments[29] < moment(this.state.filters.date.to).unix())
            segments[29] = moment(this.state.filters.date.to).unix();

        let legendAll = [];

        let chartDataObject = {
            name: 'В долларах',
            data: []
        };

        legendAll = [{
            title:  'В долларах',
            color: this.colors[3]
        }];

        for(let j=0;j<segments.length;j++){
            chartDataObject.data.push({
                date: moment(segments[j] * 1000).format('YYYY-MM-DD'),
                x: j,
                yd: 0,
            })
        }

        let currentAllAmmount = 0;
        for(let i=0;i<this.state.accounts.length;i++){
            currentAllAmmount += this.getAmountCurrency(this.state.accounts[i].amount, this.state.accounts[i].id)
        }

        for(let i=0;i<list.length;i++){
            let data_index = this.getSegmentNumber(segments, list[i].date);

            if(list[i].type === 'in')
                chartDataObject.data[data_index].yd += this.getAmountCurrency(list[i].amount, list[i].account_id);
            else
                chartDataObject.data[data_index].yd -= this.getAmountCurrency(list[i].amount, list[i].account_id);
        }

        for(let j=chartDataObject.data.length-1;j>=0;j--){
            if(j === chartDataObject.data.length-1){
                chartDataObject.data[j].y = currentAllAmmount;
            } else {
                chartDataObject.data[j].y = chartDataObject.data[j+1].y - chartDataObject.data[j+1].yd;
                delete chartDataObject.data[j+1].yd;
            }
        }

        let chartAllData = [chartDataObject];

        console.log('chartAllData: ', chartAllData);

        this.setState({chartAllData}, () => {
            this.setState({legendAll});
        })
    }

    getAmountCurrency(amount, account_from){
        if(account_from === this.state.currentAllAccount)
            return amount;

        let currency_from;
        let currency_to;
        for(let i=0;i<this.state.accounts.length;i++){
            if(account_from === this.state.accounts[i].id){
                for(let j=0;j<this.currencies.length;j++){
                    if(this.state.accounts[i].currency_id === this.currencies[j].id)
                        currency_from = this.currencies[j];
                }
            }
            if(this.state.accounts[i].id === this.state.currentAllAccount){
                for(let j=0;j<this.currencies.length;j++){
                    if(this.state.accounts[i].currency_id === this.currencies[j].id)
                        currency_to = this.currencies[j];
                }
            }
        }

        if(!currency_from || !currency_to)
            return amount;

        return (currency_from.ratio * parseFloat(amount)) / currency_to.ratio
    }

    getAccountAmount(id){
        let retData = null;
        for(let i=0;i<this.state.accounts.length;i++){
            if(this.state.accounts[i].id === id)
                retData = this.state.accounts[i].amount;
        }

        return retData;
    }

    getSegmentNumber(segments, date){
        let retval = 0;
        let timestamp = moment(date).unix();
        for(let i=1;i<segments.length;i++){
            if(timestamp > segments[i]){
                retval = i;
            }
        }

        return retval;
    }

    setFilterValue = (value, key) => {
        this.setState(prevState => {
            prevState = this.state.filters;
            if (key === 'date') {
                prevState[key] = {
                    from: value.length ? moment(value[0]).format('YYYY-MM-DD HH:mm:ss') : null,
                    to: value.length ? moment(value[1]).format('YYYY-MM-DD HH:mm:ss') : null
                };
            } else {
                prevState[key] = value;
            }

            return {form: prevState}
        }, async () => {
            await this.refreshList();
        });
    };

    _onMouseLeave = () => {
        this.setState({crosshairValues: []});
    };

    _onNearestX = (value, {index}) => {
        this.setState({crosshairValues: this.state.chartData.map(d => d.data[index])}, () => {
            this.setState({crosshairTitle: this.state.chartData[0].data[index].date})
        });
    };

    _onNearestXAll = (value, {index}) => {
        this.setState({crosshairAllValues: this.state.chartAllData.map(d => d.data[index])}, () => {
            this.setState({crosshairAllTitle: this.state.chartAllData[0].data[index].date})
        });
    };

    render() {
        return (
            <div className={'w-100'}>
                <MetaTags>
                    <title>Главная</title>
                    <meta id="meta-description" name="description" content="Главная страница" />
                    <meta id="og-title" property="og:title" content="Главная" />
                    <meta id="og-image" property="og:image" content="https://i.pinimg.com/originals/10/cf/25/10cf25ff3b572b8d2458785914b8ed39.jpg" />
                </MetaTags>
                {
                    this.state.accounts ? <div className={'col-12'}>
                        <div className={'row'}>
                            {
                                this.state.accounts.map((el, i) => {
                                    return <div className={'col-3 account-list'} key={i}>
                                        <Message type="info" title={el.name} description={
                                            <div className={'account-info'}>
                                                <span className={'amount'}>{el.amount}</span>
                                                <span className={'details'} onClick={() => this.openAccountDetails(el)}>Детальнее</span>
                                            </div>
                                        }/>
                                    </div>
                                })
                            }
                        </div>
                        <div className={'row filters dashboard-filters'}>
                            <div className={'col-3 d-flex flex-column'}>
                                <label>Тип операции:</label>
                                <InputPicker data={[
                                    {
                                        "label": "Все",
                                        "value": null
                                    },
                                    {
                                        "label": "Доходы",
                                        "value": "in"
                                    },
                                    {
                                        "label": "Затраты",
                                        "value": "out"
                                    },
                                ]} onChange={(value) => this.setFilterValue(value, 'transaction_type')} cleanable={false}
                                             defaultValue={this.state.filters.transaction_type} style={{width: '100%'}}/>
                            </div>
                            <div className={'col-3 d-flex flex-column'}>
                                <label>Период времени:</label>
                                <DateRangePicker
                                    disabledDate={afterToday()}
                                    isoWeek
                                    placement={'bottomEnd'}
                                    format="YYYY-MM-DD"
                                    defaultValue={[new Date(moment(this.state.filters.date.from)), new Date(moment(this.state.filters.date.to))]}
                                    onChange={(value) => this.setFilterValue(value, 'date')}
                                />
                            </div>
                            <div className={'col-3 d-flex flex-column'}>
                                <label>Счет:</label>
                                <InputPicker data={this.state.accounts}
                                             onChange={(value) => this.setFilterValue(value, 'account')}
                                             defaultValue={this.state.filters.account} style={{width: '100%'}}
                                             labelKey={'name'} valueKey={'id'}/>
                            </div>
                            <div className={'col-3 d-flex flex-column'}>
                                <label>Категории:</label>
                                <InputPicker data={[
                                    {
                                        "label": "Входящие",
                                        "value": "in"
                                    },
                                    {
                                        "label": "Исходящие",
                                        "value": "out"
                                    },
                                ]} onChange={(value) => this.setFilterValue(value, 'categories_id')} defaultValue={this.state.filters.categories_id} style={{width: '100%'}}/>
                            </div>
                        </div>
                    </div> : null
                }

                {
                    this.state.chartData && this.state.chartData.length ? <div className={'col-12 mb-5'}>
                        <XYPlot height={260} width={window.innerWidth-275} onMouseLeave={this._onMouseLeave}>
                            <VerticalGridLines />
                            <HorizontalGridLines />
                            <XAxis />
                            <YAxis />
                            <DiscreteColorLegend
                                style={{position: 'absolute', bottom: '-35px', left: '10px'}}
                                orientation="horizontal"
                                items={this.state.legend}
                            />
                            {
                                this.state.chartData.map((el, i) => {
                                    return <LineSeries
                                        curve={'curveMonotoneX'}
                                        data={el.data}
                                        color={this.colors[i]}
                                        onNearestX={this._onNearestX}
                                        key={'ser_' + i}
                                    />
                                })
                            }
                            <Crosshair values={this.state.crosshairValues} className={'test-class-name'}>
                                {
                                    this.state.crosshairValues && this.state.crosshairValues.length ?
                                        <div className={'legend-block'}>
                                            <div className={'title'}>{this.state.crosshairTitle}</div>
                                            {
                                                this.state.chartData.map((el, i) => {
                                                    return <span className={'legend'} key={'el_' + i}>{el.name}: {this.state.crosshairValues[i].y}</span>
                                                })
                                            }
                                        </div> : null
                                }
                            </Crosshair>
                        </XYPlot>
                    </div> : null
                }

                {
                    this.state.chartAllData && this.state.chartAllData.length ? <div className={'col-12 mt-5'}>
                        <XYPlot height={260} width={window.innerWidth-275} onMouseLeave={this._onMouseLeave}>
                            <VerticalGridLines />
                            <HorizontalGridLines />
                            <XAxis />
                            <YAxis />
                            <DiscreteColorLegend
                                style={{position: 'absolute', bottom: '-35px', left: '10px'}}
                                orientation="horizontal"
                                items={this.state.legendAll}
                            />
                            {
                                this.state.chartAllData.map((el, i) => {
                                    return <LineSeries
                                        curve={'curveMonotoneX'}
                                        data={el.data}
                                        color={this.colors[i]}
                                        onNearestX={this._onNearestXAll}
                                        key={'ser_' + i}
                                    />
                                })
                            }
                            <Crosshair values={this.state.crosshairAllValues} className={'test-class-name'}>
                                {
                                    this.state.crosshairAllValues && this.state.crosshairAllValues.length ?
                                        <div className={'legend-block'}>
                                            <div className={'title'}>{this.state.crosshairAllTitle}</div>
                                            {
                                                this.state.chartAllData.map((el, i) => {
                                                    return <span className={'legend'} key={'el_' + i}>{el.name}: {this.state.crosshairAllValues[i].y}</span>
                                                })
                                            }
                                        </div> : null
                                }
                            </Crosshair>
                        </XYPlot>
                    </div> : null
                }
            </div>
        );
    }
}
