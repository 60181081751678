import is from 'is_js';

export default {
    def(_var){
        return (_var === undefined || _var === null || _var === false) ? false : _var;
    },

    array(val) {
        return val && is.array(val);
    },

    dateTime(val, type, required = true){
        let error = null;

        if(val){
            if(!is.date(val)){
                error = 'Неправильный формат'
            }
        } else {
            if(required)
                error = 'Поле обязательное на заполнения';
        }

        return {
            status: error === null,
            message: error
        };
    },

    filled(val, required = true) {
        let error = null;
        if((!this.def(val) || val === '') && required)
            error = 'Поле обязательное на заполнения';

        return {
            status: error === null,
            message: error
        };
    },

    number(val, required = true) {
        let error = null;
        if(val){
            val = '' + val;
            if(!val.match(/^[-+0-9]{1,50}$/)){
                error = 'Неправильный формат. Введите только цифры'
            }
        } else {
            if(required)
                error = 'Поле обязательное на заполнения';
        }

        return {
            status: error === null,
            message: error
        };
    },

    float(val, required = true) {
        let error = null;
        if(val){
            val = '' + val;
            if(!val.match(/^[-+,.0-9]{1,50}$/)){
                error = 'Неправильный формат. Введите только цифры'
            }
        } else {
            if(required)
                error = 'Поле обязательное на заполнения';
        }

        return {
            status: error === null,
            message: error
        };
    },

    email(val, required = true) {
        let error = null;
        if(val){
            if(!is.email(val)){
                error = 'Неправильный формат. Введите email'
            }
        } else {
            if(required)
                error = 'Поле обязательное на заполнения';
        }

        return {
            status: error === null,
            message: error
        };
    },

    text(val, required = true) {
        let error = null;
        if(val){
            if(val.length){
                if(val.length < 2){
                    error = 'Неправильный формат текста. Введите минимум 3 символы'
                }
                /*if(!val.match(/^[0-9a-zA-Zа-яёА-ЯЁ+,\\\.;:!\?@#$&\-+ =_*]{1,1000}$/)){
                    error = 'Неправильный формат текста. Введите пожалуйста только буквы и цифры'
                }*/
            } else {
                error = 'Поле обязательное на заполнения';
            }
        } else {
            if(required)
                error = 'Поле обязательное на заполнения';
        }

        return {
            status: error === null,
            message: error
        };
    },

    password(val, required = true) {
        let error = null;
        if(val){
            if(!val.match(/^[0-9a-zA-Zа-яёА-ЯЁ_*]{1,100}$/)){
                error = 'Неправильный формат пароля. Введите пожалуйста только буквы и цифры'
            }
        } else {
            if(required)
                error = 'Поле обязательное на заполнения';
        }

        return {
            status: error === null,
            message: error
        };
    }
}