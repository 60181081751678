import React from "react";
import MetaTags from 'react-meta-tags';
import {Message, InputPicker, DateRangePicker, Icon, Tooltip, Whisper, Pagination, Modal, Button} from 'rsuite';
import 'reactjs-popup/dist/index.css';
import './Transactions.css';
import {TransactionForm} from "./transaction-form/transaction-form";
import {CostForm} from "./cost-form/cost-form";
import {TransactionService, AccountService, CostService} from '../../services';
import moment from 'moment';

const {afterToday} = DateRangePicker;

export class Transactions extends React.Component {
    constructor(props) {
        super(props);
        this.countInPage = 10;
        this.state = {
            form: null,
            filters: {
                items_type: 'default', // default | internal | cost
                transaction_type: null, // null | in | out
                account: null,
                date: {
                    from: null,
                    to: null
                },
                page: {
                    offset: 0,
                    limit: this.countInPage
                }
            },
            allForAccount: null,
            list: [],
            formType: 'transaction',
            modalElement: null,
            elementToDelete: {},
            typeToDelete: {},
            accounts: null,
            countItems: null,
            countPages: 0,
            activePage: 1,
            refresh: false,
            showPopup: false,
            showDeletePopup: false,
            modalTitle: ''
        };

        this.close = this.close.bind(this);
        this.open = this.open.bind(this);
        this.closeDelete = this.closeDelete.bind(this);
        this.openDelete = this.openDelete.bind(this);
    }

    close() {
        this.setState({ showPopup: false });
    }

    open(event = null) {
        this.setState({ showPopup: true });
    }

    closeDelete() {
        this.setState({ showDeletePopup: false });
    }

    openDelete(event = null) {
        this.setState({ showDeletePopup: true });
    }

    componentDidMount() {
        setTimeout(async () => {
            await this.refreshList();
            await this.refreshAccounts();
        });
    }

    refreshAccounts = async () => {
        return new Promise((res) => {
            AccountService.getList()
                .then(acList => {
                    this.setState({accounts: acList});
                    res(true);
                })
        });
    };

    setFilterValue = (value, key) => {
        this.setState(prevState => {
            prevState = this.state.filters;
            if (key === 'date') {
                prevState[key] = {
                    from: value.length ? moment(value[0]).format('YYYY-MM-DD HH:mm:ss') : null,
                    to: value.length ? moment(value[1]).format('YYYY-MM-DD HH:mm:ss') : null
                }
            } else {
                prevState[key] = value;
            }

            return {form: prevState}
        }, async () => {
            if(key !== 'page'){
                this.setPage(1);
            } else {
                await this.refreshList();
            }

        });
    };

    refreshList = async () => {
        return new Promise((res) => {
            let filter;

            filter = {
                transaction_type: this.state.filters.transaction_type,
                account: !this.state.allForAccount ? this.state.filters.account : this.state.allForAccount
            };
            if(this.state.filters.date && this.state.filters.date.from && this.state.filters.date.to){
                filter.startDate = this.state.filters.date.from;
                filter.endDate = this.state.filters.date.to;
            }
            if(this.state.filters.page){
                filter.offset = this.state.filters.page.offset;
                filter.limit = this.state.filters.page.limit;
            }


            this.setState({refresh: true}, () => {
                if(!this.state.allForAccount && this.state.filters.items_type === 'default'){
                    TransactionService.getListDefault(filter)
                        .then(data => {
                            this.setState({countItems: data.count}, () => {
                                this.state.countPages = Math.ceil(this.state.countItems/this.countInPage);
                                this.setState({list: data.list}, () => {
                                    this.setState({refresh: false}, () => {
                                        res(true);
                                    })
                                })
                            })
                        })
                }
                if(!this.state.allForAccount && this.state.filters.items_type === 'internal'){
                    TransactionService.getListInternal(filter)
                        .then(data => {
                            this.setState({countItems: data.count}, () => {
                                this.state.countPages = Math.ceil(this.state.countItems/this.countInPage);
                                this.setState({list: data.list}, () => {
                                    this.setState({refresh: false}, () => {
                                        res(true);
                                    })
                                })
                            })
                        })
                }
                if(!this.state.allForAccount && this.state.filters.items_type === 'cost'){
                    CostService.getList(filter)
                        .then(data => {
                            this.setState({countItems: data.count}, () => {
                                this.state.countPages = Math.ceil(this.state.countItems/this.countInPage);
                                this.setState({list: data.list}, () => {
                                    this.setState({refresh: false}, () => {
                                        res(true);
                                    })
                                })
                            })
                        })
                }
                if(this.state.allForAccount){
                    TransactionService.getListForAccount(filter)
                        .then(data => {
                            this.setState({countItems: data.count}, () => {
                                this.state.countPages = Math.ceil(this.state.countItems/this.countInPage);
                                this.setState({list: data.list}, () => {
                                    this.setState({refresh: false}, () => {
                                        res(true);
                                    })
                                })
                            })
                        })
                }
            })
        });
    };

    setPage = (eventKey) => {
        this.setState({activePage: eventKey}, () => {
            this.setFilterValue({
                offset: (eventKey - 1) * this.countInPage,
                limit: this.countInPage
            }, 'page');
        });
    };

    openAccountDetails(account){
        this.setState({allForAccount: account.id}, async () => {
            this.setPage(1);
        })
    }

    backToLists(){
        this.setState({allForAccount: null}, async () => {
            this.setPage(1);
        })
    }

    openTransaction = (el = null) => {
        this.setState({formType: 'transaction'}, () => {
            if(el){
                this.setState({modalTitle: el.name}, () => {
                    if (el.type === 'in' || el.type === 'out')
                        el.form_type = 'default';
                    else
                        el.form_type = 'internal';

                    this.setState({modalElement: el}, () => {
                        this.setState({modalTitle: 'Транзакция №' + el.id}, () => {
                            this.open()
                        });
                    });
                });
            } else {
                this.setState({modalElement: el}, () => {
                    this.setState({modalTitle: 'Новая транзакция'}, () => {
                        this.open()
                    });
                });
            }
        });
    };

    openCost(el = null){
        if(el){
            CostService.getCost(el.id)
                .then(costData => {
                    if (costData && costData.transactions && costData.transactions.length) {
                        for (let i = 0; i < costData.transactions.length; i++) {
                            costData.transactions[i].transaction_type = 'default'
                        }
                    }
                    if (costData && costData.internal_transactions && costData.internal_transactions.length) {
                        for (let i = 0; i < costData.internal_transactions.length; i++) {
                            costData.transactions.push({
                                transaction_type: 'internal',
                                account_from: costData.internal_transactions[i].account_from,
                                account_to: costData.internal_transactions[i].account_to,
                                amount_from: costData.internal_transactions[i].amount_from,
                                amount_to: costData.internal_transactions[i].amount_to,
                                commission: costData.internal_transactions[i].commission,
                                cost_id: costData.internal_transactions[i].cost_id,
                                id: costData.internal_transactions[i].id,
                                rate: costData.internal_transactions[i].rate,
                                type: costData.internal_transactions[i].type,
                                user_id: costData.internal_transactions[i].user_id
                            });
                        }
                    }

                    this.setState({formType: 'cost'}, () => {
                        this.setState({modalTitle: 'Покупка "' + el.name +  '"'}, () => {
                            this.setState({modalElement: costData}, () => {
                                this.open();
                            });
                        });
                    });
                });
        } else {
            this.setState({formType: 'cost'}, () => {
                this.setState({modalTitle: 'Новая покупка'}, () => {
                    this.setState({modalElement: null}, () => {
                        this.open();
                    });
                });

            });
        }

    }

    formChanged(event) {
        this.setState({form: event}, async () => {
            await this.refreshAccounts();
            await this.refreshList();
            this.close()
        });
    }

    openDeleteTransaction(item){
        this.setState({elementToDelete: item}, () => {
            this.setState({typeToDelete: 'transaction'}, () => {
                this.openDelete();
            })
        })
    }

    openDeleteCost(item){
        this.setState({elementToDelete: item}, () => {
            this.setState({typeToDelete: 'cost'}, () => {
                this.openDelete();
            })
        })
    }

    deleteConfirm(){
        if(this.state.typeToDelete === 'transaction'){
            if(this.state.filters.items_type === 'internal' || this.state.elementToDelete.internal_transaction_id){
                TransactionService.deleteInternalTransaction(this.state.elementToDelete.id)
                    .then(async (delData) => {
                        await this.refreshList();
                        await this.refreshAccounts();
                        this.closeDelete();
                    })
            } else {
                TransactionService.deleteDefaultTransaction(this.state.elementToDelete.id)
                    .then(async (delData) => {
                        await this.refreshList();
                        await this.refreshAccounts();
                        this.closeDelete();
                    })
            }
        } else {
            CostService.deleteCost(this.state.elementToDelete.id)
                .then(async (delData) => {
                    await this.refreshList();
                    await this.refreshAccounts();
                    this.closeDelete();
                })
        }
    }

    render() {
        return (
            <div className={'w-100'}>
                <MetaTags>
                    <title>Транзакции</title>
                    <meta id="meta-description" name="description" content="Транзакции" />
                    <meta id="og-title" property="og:title" content="Транзакции" />
                    <meta id="og-image" property="og:image" content="https://i.pinimg.com/originals/10/cf/25/10cf25ff3b572b8d2458785914b8ed39.jpg" />
                </MetaTags>

                {
                    this.state.accounts && !this.state.allForAccount ? <div className={'col-12'}>
                        <div className={'row filters'}>
                            <div className={'col-3 d-flex flex-column'}>
                                <label>Тип операции:</label>
                                <InputPicker data={[
                                    {
                                        "label": "Обычная",
                                        "value": "default"
                                    },
                                    {
                                        "label": "Внутренняя",
                                        "value": "internal"
                                    },
                                    {
                                        "label": "Покупка",
                                        "value": "cost"
                                    },
                                ]} onChange={(value) => this.setFilterValue(value, 'items_type')} cleanable={false}
                                             defaultValue={this.state.filters.items_type} style={{width: '100%'}}/>
                            </div>
                            <div className={'col-3 d-flex flex-column'}>
                                <label>Тип транзакции:</label>
                                <InputPicker data={[
                                    {
                                        "label": "Входящие",
                                        "value": "in"
                                    },
                                    {
                                        "label": "Исходящие",
                                        "value": "out"
                                    },
                                ]} onChange={(value) => this.setFilterValue(value, 'transaction_type')} defaultValue={this.state.filters.transaction_type} style={{width: '100%'}}/>
                            </div>
                            <div className={'col-3 d-flex flex-column'}>
                                <label>Счет:</label>
                                <InputPicker data={this.state.accounts}
                                             onChange={(value) => this.setFilterValue(value, 'account')}
                                             defaultValue={this.state.filters.account} style={{width: '100%'}}
                                             labelKey={'name'} valueKey={'id'}/>
                            </div>
                            <div className={'col-3 d-flex flex-column'}>
                                <label>Период времени:</label>
                                <DateRangePicker disabledDate={afterToday()} isoWeek placement={'bottomEnd'}
                                                 onChange={(value) => this.setFilterValue(value, 'date')}/>
                            </div>
                        </div>
                        <div className={'row'}>
                            {
                                this.state.accounts.map((el, i) => {
                                    return <div className={'col-3 account-list'} key={i}>
                                        <Message type="info" title={el.name} description={
                                            <div className={'account-info'}>
                                                <span className={'amount'}>{el.amount}</span>
                                                <span className={'details'} onClick={() => this.openAccountDetails(el)}>Детальнее</span>
                                            </div>
                                        }/>
                                    </div>
                                })
                            }
                        </div>
                    </div> : null
                }

                {
                    this.state.allForAccount ? <div className={'row'}>
                        <div className={'col-3 d-flex align-items-center pl-4'}>
                            <div className={'back-block'} onClick={() => this.backToLists()}>
                                <Icon icon='angle-left'/>
                                Назад
                            </div>
                        </div>
                        <div className={'col-3 d-flex flex-column'}>
                            <label>Тип транзакции:</label>
                            <InputPicker data={[
                                {
                                    "label": "Входящие",
                                    "value": "in"
                                },
                                {
                                    "label": "Исходящие",
                                    "value": "out"
                                },
                            ]} onChange={(value) => this.setFilterValue(value, 'transaction_type')} defaultValue={this.state.filters.transaction_type} style={{width: '100%'}}/>
                        </div>
                        <div className={'col-3 d-flex flex-column'}>
                            <label>Период времени:</label>
                            <DateRangePicker isoWeek placement={'bottomEnd'} disabledDate={afterToday()} onChange={(value) => this.setFilterValue(value, 'date')}/>
                        </div>
                    </div> : null
                }

                {
                    !this.state.allForAccount && this.state.filters.items_type === 'default' ? <div className={'row mt-4'}>
                        <div className={'col-12'}>
                            <table className="table table-striped">
                                <thead>
                                <tr>
                                    <th scope="col">Счет</th>
                                    <th scope="col">Сумма</th>
                                    <th scope="col">Тип</th>
                                    <th scope="col">Покупка</th>
                                    <th scope="col">Категория</th>
                                    <th scope="col">Комментарий</th>
                                    <th scope="col">Дата</th>
                                    <th scope="col"></th>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    this.state.list.map((el, i) => {
                                        return <tr className={'c-pointer'} key={i}>
                                            <td onClick={() => this.openTransaction(el)}>{el.account?.name}</td>
                                            <td onClick={() => this.openTransaction(el)}>{el.amount}</td>
                                            <td onClick={() => this.openTransaction(el)}>{el.type}</td>
                                            <td onClick={() => this.openTransaction(el)}>{el.cost?.name}</td>
                                            <td onClick={() => this.openTransaction(el)}>{el.show_category}</td>
                                            <td onClick={() => this.openTransaction(el)}>{el.comment}</td>
                                            <td onClick={() => this.openTransaction(el)}>{el.date}</td>
                                            <td>
                                                <Whisper onClick={() => this.openDeleteTransaction(el)} placement="left" trigger="hover" speaker={<Tooltip>Удалить транзакцию</Tooltip>}>
                                                    <Icon icon='trash-o'/>
                                                </Whisper>
                                            </td>
                                        </tr>
                                    })
                                }
                                </tbody>
                            </table>
                        </div>
                    </div> : null
                }

                {
                    !this.state.allForAccount && this.state.filters.items_type === 'internal' ? <div className={'row mt-4'}>
                        <div className={'col-12'}>
                            <table className="table table-striped">
                                <thead>
                                <tr>
                                    <th scope="col">Откуда</th>
                                    <th scope="col">Сумма</th>
                                    <th scope="col">Куда</th>
                                    <th scope="col">Сумма</th>
                                    <th scope="col">Курс</th>
                                    <th scope="col">Коммисия</th>
                                    <th scope="col">Тип</th>
                                    <th scope="col">Дата</th>
                                    <th></th>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    this.state.list.map((el, i) => {
                                        return <tr className={'c-pointer'} key={i}>
                                            <td onClick={() => this.openTransaction(el)}>{el.from_account?.name}</td>
                                            <td onClick={() => this.openTransaction(el)}>{el.amount_from}</td>
                                            <td onClick={() => this.openTransaction(el)}>{el.to_account?.name}</td>
                                            <td onClick={() => this.openTransaction(el)}>{el.amount_to}</td>
                                            <td onClick={() => this.openTransaction(el)}>{el.rate}</td>
                                            <td onClick={() => this.openTransaction(el)}>{el.commission}</td>
                                            <td onClick={() => this.openTransaction(el)}>{el.type}</td>
                                            <td onClick={() => this.openTransaction(el)}>{el.date}</td>
                                            <td>
                                                <Whisper onClick={() => this.openDeleteTransaction(el)}  placement="left" trigger="hover" speaker={<Tooltip>Удалить транзакцию</Tooltip>}>
                                                    <Icon icon='trash-o'/>
                                                </Whisper>
                                            </td>
                                        </tr>
                                    })
                                }
                                </tbody>
                            </table>
                        </div>
                    </div> : null
                }

                {
                    !this.state.allForAccount && this.state.filters.items_type === 'cost' ? <div className={'row mt-4'}>
                        <div className={'col-12'}>
                            <table className="table table-striped">
                                <thead>
                                <tr>
                                    <th scope="col">Название</th>
                                    <th scope="col">Дата</th>
                                    <th scope="col"></th>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    this.state.list.map((el, i) => {
                                        return <tr className={'c-pointer'} key={i}>
                                            <td onClick={() => this.openCost(el)}>{el.name}</td>
                                            <td onClick={() => this.openCost(el)}>{el.date}</td>
                                            <td>
                                                <Whisper onClick={() => this.openDeleteCost(el)} placement="left" trigger="hover" speaker={<Tooltip>Удалить покупку</Tooltip>}>
                                                    <Icon icon='trash-o'/>
                                                </Whisper>
                                            </td>
                                        </tr>
                                    })
                                }
                                </tbody>
                            </table>
                        </div>
                    </div> : null
                }

                {
                    this.state.allForAccount ? <div className={'row mt-4'}>
                        <div className={'col-12'}>
                            <table className="table table-striped">
                                <thead>
                                <tr>
                                    <th scope="col">Счет</th>
                                    <th scope="col">Сумма</th>
                                    <th scope="col">Тип</th>
                                    <th scope="col">Покупка</th>
                                    <th scope="col">Категория</th>
                                    <th scope="col">Комментарий</th>
                                    <th scope="col">Дата</th>
                                    <th scope="col"></th>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    this.state.list.map((el, i) => {
                                        return <tr className={'c-pointer'} key={i}>
                                            <td onClick={() => this.openTransaction(el)}>{el.account?.name}</td>
                                            <td onClick={() => this.openTransaction(el)}>{el.amount}</td>
                                            <td onClick={() => this.openTransaction(el)}>{el.type}</td>
                                            <td onClick={() => this.openTransaction(el)}>{el.cost?.name}</td>
                                            <td onClick={() => this.openTransaction(el)}>{el.show_category}</td>
                                            <td onClick={() => this.openTransaction(el)}>{el.comment}</td>
                                            <td onClick={() => this.openTransaction(el)}>{el.date}</td>
                                            <td>
                                                {
                                                    !el.internal_transaction_id ?
                                                    <Whisper onClick={() => this.openDeleteTransaction(el)}  placement="left" trigger="hover" speaker={<Tooltip>Удалить транзакцию</Tooltip>}>
                                                        <Icon icon='trash-o'/>
                                                    </Whisper> : null
                                                }
                                            </td>
                                        </tr>
                                    })
                                }
                                </tbody>
                            </table>
                        </div>
                    </div> : null
                }

                {
                    !this.state.refresh ? <div className={'row'}>
                        <div className={'col-12 d-flex justify-content-center'}>
                            <Pagination
                                prev={true}
                                next={true}
                                first={true}
                                last={true}
                                pages={this.state.countPages}
                                maxButtons={5}
                                activePage={this.state.activePage}
                                onSelect={this.setPage}
                            />
                        </div>
                    </div> : null
                }

                <Modal size={'lg'} overflow={false} show={this.state.showPopup} onHide={this.close}>
                    <Modal.Header>
                        <Modal.Title>
                            <div className={'w-100 d-flex justify-content-center'}>
                                {this.state.modalTitle}
                            </div>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className={'modal-form my-scrollbar'}>
                            {
                                this.state.formType === 'transaction' ?
                                    <TransactionForm transaction={this.state.modalElement}
                                                     change={(event) => this.formChanged(event)}/> : null
                            }
                            {
                                this.state.formType === 'cost' ?
                                    <CostForm cost={this.state.modalElement} accounts={this.state.accounts}
                                              change={(event) => this.formChanged(event)}/> : null
                            }
                        </div>
                    </Modal.Body>
                </Modal>

                <Modal backdrop="static" show={this.state.showDeletePopup} onHide={this.closeDelete} size="xs">
                    <Modal.Body>
                        <div className={'w-100 d-flex flex-column align-items-center'}>
                            <Icon icon="remind" style={{color: '#ffb300', fontSize: 24}} />
                            <span>Вы уверены что хотите удалить {this.state.elementToDelete && this.state.elementToDelete.name ? 'покупку' : 'транзакцию'}</span>
                            <span>{this.state.elementToDelete && this.state.elementToDelete.name ? '"' + this.state.elementToDelete.name + '"' : '№' + this.state.elementToDelete.id}</span>
                            <span className={'mb-4'}>?</span>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={this.closeDelete} appearance="subtle">
                            Отмена
                        </Button>
                        <Button onClick={(event) => this.deleteConfirm()} appearance="primary">
                            Удалить
                        </Button>
                    </Modal.Footer>
                </Modal>

                <div className={'add-buttons'}>
                    <Whisper placement="top" trigger="hover" speaker={<Tooltip>Добавить транзакцию</Tooltip>}>
                        <div className={'add-item'} onClick={() => this.openTransaction()}>
                            <Icon icon='plus' size="lg"/>
                        </div>
                    </Whisper>
                    <Whisper placement="top" trigger="hover" speaker={<Tooltip>Добавить покупку</Tooltip>}>
                        <div className={'add-item'} onClick={() => this.openCost()}>
                            <Icon icon='list-ul' size="lg"/>
                        </div>
                    </Whisper>
                </div>
            </div>
        );
    }
}