import React from "react";
import {NavLink} from "react-router-dom";
import './Sidebar.css';

export function Sidebar() {
    return (
        <div className="sidebar">
            <div className="sidebar-content">
                <NavLink exact to="/" activeClassName="active">Главная</NavLink>
                <NavLink exact to="/transactions" activeClassName="active">Транзакции</NavLink>
                <NavLink exact to="/accounts" activeClassName="active">Счета</NavLink>
                <NavLink exact to="/income-categories" activeClassName="active">Категории прибыли</NavLink>
                <NavLink exact to="/expense-categories" activeClassName="active">Категории затрат</NavLink>
                <NavLink exact to="/currencies" activeClassName="active">Валюты</NavLink>
            </div>
        </div>
    );
}