import React, { Component } from "react";
import './custom-select.css';
import { InputPicker, TreePicker } from 'rsuite';
import {Validate} from "../../libs";
import {AutocompleteService} from "../../services";

export class CustomSelect extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value: null,
            options: this.props.options || [],
            showValue: '',
            valid: {
                status: false,
                message: ''
            },
            error: null,
            openedOptions: false,
            service: null,
        };

        this.key = this.props.keyVal || 'id';
        this.show = this.props.showVal || 'name';
        this.validatorType = this.props.validatorType || 'filled';
        this.required = this.props.required || false;
        this.type = this.props.type || 'default';
        this.setStateValue = this.setStateValue.bind(this);
    }

    componentDidMount() {
        if(this.props.service){
            this.setState({service: this.props.service}, () => {
                AutocompleteService.autocomplete(this.state.service, this.state.search, this.props.value || '')
                    .then(respList => {
                        this.setState({options: respList}, () => {
                            this.setStateValue(this.props.value || '');
                        })
                    })
            });
        } else {
            this.setStateValue(this.props.value || '');
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps && nextProps.service && nextProps.service !== this.state.service) {
            this.setState({service: nextProps.service}, () => {
                AutocompleteService.autocomplete(this.state.service, this.state.search, this.props.value || '')
                    .then(respList => {
                        this.setState({options: respList}, () => {
                            this.setStateValue(this.props.value || '');
                        })
                    })
            });

        }
    }

    setStateValue(value){
        if(value && value !== ''){
            this.setState({value: value}, () => {
                this.setState({valid: Validate[this.validatorType](this.state.value, this.required)}, () => {
                    this.props.change({
                        valid: this.state.valid && this.state.valid.status,
                        value: this.state.value
                    });
                });
            });
        } else {
            this.setState({valid: Validate[this.validatorType](this.state.value, this.required)}, () => {
                this.props.change({
                    valid: this.state.valid && this.state.valid.status,
                    value: this.state.value
                });
            });
        }
    }

    render() {
        return (
            <div className={(!this.state.valid.status && this.props.showError) ? 'custom-input-block error' : 'custom-input-block'}>
                <div className={'custom-label'}>{this.props.label}</div>
                {
                    this.type === 'default' ? <InputPicker data={this.state.options} labelKey={this.show} valueKey={this.key} style={{width: '100%', padding: 0}} className={'form-control'} defaultValue={this.props.value} onChange={this.setStateValue} /> : null
                }
                {
                    this.type === 'tree' ? <TreePicker
                        data={this.state.options}
                        value={this.props.value}
                        placeholder={this.props.placeholder}
                        defaultValue={this.props.value}
                        onChange={this.setStateValue}
                        labelKey={this.show}
                        valueKey={this.key}
                        renderTreeIcon={this.renderTreeIcon}

                        className={'form-control'}
                        style={{width: '100%', padding: 0}}
                    /> : null
                }

                {
                    !this.state.valid.status && this.props.showError ? <span className={'custom-error'}>{this.state.valid.message}</span> : null
                }
            </div>
        );
    }
}