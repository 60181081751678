import {UserService} from '../services'
import API from "../utils/Api";

export default {
    user: getUser(),
    async authenticate(data, cb) {
        UserService.signIn(data)
            .then(authData => {
                this.user = authData;
                localStorage.setItem('user', JSON.stringify(this.user));
                API.defaults.headers['token'] = this.user.token;
                cb();
            })
    },
    signout(cb) {
        this.user = null;
        localStorage.removeItem('user');
        delete API.defaults.headers['token'];
        setTimeout(cb, 100);
    }
};

function getUser(){
    let user = localStorage.getItem('user');
    user = user ? JSON.parse(user) : null;
    if(user){
        API.defaults.headers['token'] = user.token;
    } else {
        delete API.defaults.headers['token'];
    }
    return user;
}